import React, {Suspense} from "react";
import "slick-carousel/slick/slick.css";
import "../scss/style.scss";
import dynamic from 'next/dynamic';
import type {AppProps} from 'next/app'
import ErrorBoundary from "../ErrorBoundary";

const AppWrapper = dynamic(() => import('../AppWrapper'))
const CustomWrapper = dynamic(() => import('../CustomWrapper'))


export default function App({Component, pageProps}: AppProps) {
    return (
        <ErrorBoundary>
            {/*<Suspense fallback={<p>Loading feed...</p>}>*/}
                <AppWrapper>
                    <CustomWrapper Component={Component} pageProps={pageProps}/>
                </AppWrapper>
            {/*</Suspense>*/}
        </ErrorBoundary>

    );
}
