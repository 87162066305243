import Router from 'next/router'
import React, {Component, ErrorInfo, ReactNode} from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        window.localStorage.clear()
        localStorage.clear()
        return {hasError: true};
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {

        if (this.state.hasError) {
            return (
                <div className="error-page-container">
                    <div className="error-page-container_section">
                        <h3>Sorry something went wrong, our developers are working hard to fix it as fast as it
                            possible </h3>
                        <p>
                            <span onClick={() => {
                                this.setState({hasError: false})
                                Router.push('/')
                            }}>
                                Go to Home page
                            </span>
                        </p>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
